import payload_plugin_qLmFnukI99 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_KAumv2pUni from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_kyYn5AnnAK from "/vercel/path0/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_BwfRt8ewMW from "/vercel/path0/node_modules/vue-sonner/lib/nuxt/runtime/plugin.js";
import siteConfig_JRId4KOeUL from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import _0_app_AIXhssp0yh from "/vercel/path0/plugins/0.app.js";
import auth_ANExhJHvcR from "/vercel/path0/plugins/auth.js";
import axios_sVCuMR6hEC from "/vercel/path0/plugins/axios.js";
import cloud_R9ItJ2WfY0 from "/vercel/path0/plugins/cloud.js";
import command_palette_client_GoFUndnueO from "/vercel/path0/plugins/command-palette.client.ts";
import dayjs_FoMn5Zigri from "/vercel/path0/plugins/dayjs.js";
import dexie_client_wBkDMw5efv from "/vercel/path0/plugins/dexie.client.js";
import events_client_2jCnq58JmV from "/vercel/path0/plugins/events.client.js";
import importer_client_grDAm758wV from "/vercel/path0/plugins/importer.client.js";
import mitt_client_JdecFmZkyR from "/vercel/path0/plugins/mitt.client.js";
import modal_client_5sTXjXRW3L from "/vercel/path0/plugins/modal.client.ts";
import photoswipe_client_Fu0OWPs5r7 from "/vercel/path0/plugins/photoswipe.client.js";
import swiper_client_ShUaKjxIOB from "/vercel/path0/plugins/swiper.client.js";
import sync_client_XCSwmFlqsC from "/vercel/path0/plugins/sync.client.js";
import tippy_UYJccKXDFD from "/vercel/path0/plugins/tippy.js";
import uuid_TvkSiJGZZ0 from "/vercel/path0/plugins/uuid.js";
import wowfactor_client_YOMyjWF2AD from "/vercel/path0/plugins/wowfactor.client.js";
import zapp_client_5AUcZ9x74d from "/vercel/path0/plugins/zapp.client.js";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/vercel/path0/.nuxt/vuetify-nuxt-plugin.client.mjs";
import defaults_M8ptihKv0z from "/vercel/path0/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  vuetify_icons_KAumv2pUni,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vuetify_no_client_hints_kyYn5AnnAK,
  plugin_BwfRt8ewMW,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  _0_app_AIXhssp0yh,
  auth_ANExhJHvcR,
  axios_sVCuMR6hEC,
  cloud_R9ItJ2WfY0,
  command_palette_client_GoFUndnueO,
  dayjs_FoMn5Zigri,
  dexie_client_wBkDMw5efv,
  events_client_2jCnq58JmV,
  importer_client_grDAm758wV,
  mitt_client_JdecFmZkyR,
  modal_client_5sTXjXRW3L,
  photoswipe_client_Fu0OWPs5r7,
  swiper_client_ShUaKjxIOB,
  sync_client_XCSwmFlqsC,
  tippy_UYJccKXDFD,
  uuid_TvkSiJGZZ0,
  wowfactor_client_YOMyjWF2AD,
  zapp_client_5AUcZ9x74d,
  vuetify_nuxt_plugin_client_JOtFoHdxcp,
  defaults_M8ptihKv0z
]